import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMetadata } from '../../context/metadata';

export const CallForm = () => {
  const { phone, workingSchedule } = useMetadata();
  const { t } = useTranslation('common');

  return (
    <Box
      sx={{
        padding: {
          sm: '0 1rem 0',
        },
      }}
    >
      <Box
        sx={{
          paddingBottom: '2rem',
          '& svg': {
            verticalAlign: 'middle',
            fontSize: '1.2em',
            height: '1em',
            width: '1.2em',
          },
        }}
      >
        <Typography sx={{ fontWeight: 600 }} variant="subtitle1">
          {t('call-form-title')}
        </Typography>
        <Typography sx={{ fontSize: '14px' }} component="div">
          {workingSchedule.map(({ day, time }) => (
            <p key={`schedule-${day}-${time}`}>{`${day}: ${time}`}</p>
          ))}
        </Typography>
      </Box>

      <Button
        sx={{
          textAlign: 'left',
          fontSize: '1.1rem',
          textTransform: 'none',
          sm: {
            fontSize: '1.5rem',
          },
        }}
        href={`tel:${phone}`}
        target="_blank"
        size="large"
        rel="noopener noreferrer"
        fullWidth
        variant="outlined"
      >
        {t(`glossary:call`)} {phone}
      </Button>
    </Box>
  );
};
