import { PublicSearchVehicle } from '@bisondesk/core-sdk/lib/types/vehicles';
import { graphql } from 'gatsby';
import React, { useMemo } from 'react';
import VehicleDetails from '../components/vehicles/details/Vehicle';
import Layout from '../layout/Layout';

type Props = {
  params: { slug: string };
  location: Location;
  data: { vehicle: { data: string } };
};

export default (props: Props) => {
  const data: PublicSearchVehicle = useMemo(
    () => JSON.parse(props.data.vehicle.data),
    [props.data]
  );

  return (
    <Layout location={props.location}>
      <VehicleDetails vehicle={data.vehicle} />
    </Layout>
  );
};

export const pageQuery = graphql`
  query ($slug: String!) {
    vehicle(slug: { eq: $slug }) {
      data
    }
  }
`;
