import { PublicVehicle } from '@bisondesk/core-sdk/lib/types/vehicles';
import { Check } from '@mui/icons-material';
import { Box, Grid, Typography } from '@mui/material';
import { styled } from '@mui/styles';
import { SxProps } from '@mui/system';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { theme } from '../../../layout/styles';
import { concatSx } from '../../../utils/formatters';

const DEFAULT_ZEBRA_COLOR = '#faf8f9';

type ContainerProps = {
  children: React.ReactElement<SectionProps> | React.ReactElement<SectionProps>[];
  alternateColor?: string;
};

type SectionProps = {
  title: string;
  alternateColor?: string;
  children: React.ReactElement<RowProps> | React.ReactElement<RowProps>[];
};

type RowType = 'boolean' | 'key-value';

type RowProps = {
  type?: RowType;
  label: string;
  value: React.ReactNode | undefined;
};

type VehicleSpecificationsSection = {
  title: string;
  dataType?: RowType;
  items: {
    label: string;
    value: number | string | boolean | undefined;
  }[];
};

type VehicleSpecificationsProps = {
  features: VehicleSpecificationsSection[];
  vehicle: PublicVehicle;
};

const textStyles: SxProps = {
  lineHeight: '1.3em',
  fontSize: '.9rem',
  textAlign: 'left',
  wordBreak: 'break-word',
};

const labelStyles: SxProps = {
  fontWeight: 600,
};
const rowStyles: SxProps = {
  columnCount: 2,
  padding: '8px',
};

const SpecificationRow = ({ type = 'key-value', ...otherProps }: RowProps) => {
  switch (type) {
    case 'boolean':
      return <BooleanRow {...otherProps} />;
    case 'key-value':
    default:
      return <KeyValueRow {...otherProps} />;
  }
};

const KeyValueRow = ({ value, label }: Omit<RowProps, 'type'>) => {
  return (
    <Box sx={rowStyles}>
      <Typography variant="h5" sx={concatSx(textStyles, labelStyles)}>
        {label}
      </Typography>
      <Box sx={textStyles}>{value}</Box>
    </Box>
  );
};

const BooleanRow = ({ label }: Omit<RowProps, 'type'>) => {
  return (
    <Box
      sx={concatSx(rowStyles, textStyles, {
        columnCount: 1,
        paddingLeft: '3em',
        position: 'relative',
      })}
    >
      <Check
        style={{
          color: theme.palette.primary.main,
          verticalAlign: 'middle',
          fontSize: '1.5em',
          left: '8px',
          position: 'absolute',
        }}
      />
      {label}
    </Box>
  );
};

const SpecificationsSection = ({
  title,
  children,
  alternateColor = DEFAULT_ZEBRA_COLOR,
}: SectionProps) => {
  const filteredChildren: React.ReactElement<RowProps>[] = [];

  React.Children.forEach(children, (child) => !!child.props.value && filteredChildren.push(child));

  return filteredChildren.length > 0 ? (
    <Box sx={{ width: 1 }}>
      <Typography variant="h2" sx={{ fontSize: '1.1rem', fontWeight: 600 }}>
        {title}
      </Typography>
      <Box
        sx={{
          padding: '1rem 0',
          '& > div:nth-of-type(odd)': {
            backgroundColor: alternateColor,
          },
        }}
      >
        {filteredChildren}
      </Box>
    </Box>
  ) : null;
};

const SpecificationsContainer = ({
  alternateColor = DEFAULT_ZEBRA_COLOR,
  children,
}: ContainerProps) => {
  return (
    <>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          ...child.props,
          alternateColor: child.props.alternateColor ?? alternateColor,
        });
      })}
    </>
  );
};

const GridWithSpacing = styled(Grid)({
  display: 'grid',
  gap: '12px',
});

export const VehicleSpecifications = ({ features, vehicle }: VehicleSpecificationsProps) => {
  const { t } = useTranslation('glossary');
  const accessoriesTranslations: Record<string, string> = {
    abs: 'Abs',
    adr: 'Adr',
    airco: 'Airco',
    alloyWheels: 'Alloy Wheels',
    aluminiumFuelTank: 'Aluminium Fuel Tank',
    cdPlayer: 'CD Player',
    centralLocking: 'Central locking',
    centralLubrication: 'Central lubrification',
    crane: 'Crane',
    cruiseControl: 'Cruise control',
    electricDoorMirrors: 'Eletric door mirrors',
    electricWindows: 'Electric windows',
    engineBrake: 'Engine brake',
    forklift: 'Fork lift',
    navigationSystem: 'Navigation system',
    hydraulicTipperKit: 'Hydraulic tipper kit',
    lowDeck: 'Low deck',
    lowNoise: 'Low noise',
    parkingHeater: 'Parking heater',
    particleFilter: 'Particle filter',
    powerTakeOff: 'Power take off',
    refrigerator: 'Refrigerator',
    retarderIntarder: 'Retarder intarder',
    reversingCamera: 'Reversing camera',
    roofSpoiler: 'Roof spoiler',
    sideSkirts: 'Side skirts',
    spareKey: 'Spare key',
    spareWheel: 'Spare wheel',
    speedLimiter: 'Speed limiter',
    spoilers: 'Spoilers',
    spotlights: 'Spotlights',
    stabilityControl: 'Stability control',
    standardAirco: 'Standard airco',
    television: 'Television',
    toolbox: 'Toolbox',
    trailerCoupling: 'Trailer coupling',
    twinFuelTank: 'Twin fuel tank',
    visor: 'Visor',
    xenonLights: 'Xenon lights',
  };

  const accessories = Object.entries(vehicle.external.accessories).map(([key, value]) => ({
    label: accessoriesTranslations[key],
    value,
  }));

  return (
    <Grid container spacing={3}>
      <GridWithSpacing item xs={12} sm={7}>
        <SpecificationsContainer>
          {features.map((feature) => (
            <SpecificationsSection key={`vehicle-feature-${feature.title}`} title={feature.title}>
              {feature.items.map(({ label, value }) => (
                <SpecificationRow
                  key={`vehicle-feature-row-${label}`}
                  value={value}
                  type={feature.dataType}
                  label={label}
                />
              ))}
            </SpecificationsSection>
          ))}
        </SpecificationsContainer>
      </GridWithSpacing>
      <GridWithSpacing item xs={12} sm={5}>
        <SpecificationsContainer>
          <SpecificationsSection title={t('features')}>
            {accessories.map(({ label, value }) => (
              <SpecificationRow
                key={`vehicle-accessory-${label}`}
                value={value}
                type={'boolean'}
                label={label}
              />
            ))}
          </SpecificationsSection>
        </SpecificationsContainer>
      </GridWithSpacing>
    </Grid>
  );
};
