import { EuroSymbol } from '@mui/icons-material';
import { InputAdornment, TextField, TextFieldProps, Theme } from '@mui/material';
import { styled, SxProps } from '@mui/system';
import { Field } from 'formik';
import { TextField as FormikTextField } from 'formik-material-ui';
import React from 'react';
import { theme } from '../../../../layout/styles';

type CurrencyFieldProps = {
  min?: number;
  max?: number;
  name: string;
  [key: string]: any;
};

const styles: SxProps = {
  '& .Mui-disabled': {
    color: '#212B36',
    '& .MuiInputBase-input': {
      height: '2em',
      background: (theme: Theme) => theme.palette.grey[300],
    },
  },
  '& .MuiInputBase-input': {
    height: '2em',
    textAlign: 'center',
    paddingLeft: '14px',
  },
  '& .MuiOutlinedInput-root': {
    paddingLeft: 0,
    '& fieldset': {
      borderColor: (theme: Theme) => theme.palette.grey[400],
    },
  },
};

const Adornment = styled(InputAdornment)({
  marginRight: 0,
  borderRight: `1px solid ${theme.palette.grey[400]}`,
  padding: '1em',
  display: 'grid',
  alignSelf: 'stretch',
  maxHeight: '100%',
  height: 'inherit',
  '& .MuiSvgIcon-root': {
    fontSize: '1rem',
  },
  '& .notranslate': {
    display: 'none',
  },
});

const EuroIcon = () => (
  <Adornment position="start">
    <EuroSymbol fontSize="small" />
  </Adornment>
);

const commonFieldProps: TextFieldProps = {
  size: 'small',
  type: 'number',
  variant: 'outlined',
  InputProps: {
    startAdornment: <EuroIcon />,
  },
};

export const CurrencyField = (props: TextFieldProps) => {
  return <TextField sx={styles} {...props} {...commonFieldProps} />;
};

export const CurrencyFormikField = ({ name, min = 0, max, ...otherProps }: CurrencyFieldProps) => {
  return (
    <Field
      sx={{
        '& .Mui-disabled': {
          color: '#212B36',
          '& .MuiInputBase-input': {
            height: '2em',
            background: (theme: Theme) => theme.palette.grey[300],
          },
        },
        '& .MuiInputBase-input': {
          height: '2em',
          textAlign: 'center',
          paddingLeft: '14px',
        },
        '& .MuiOutlinedInput-root': {
          paddingLeft: 0,
          '& fieldset': {
            borderColor: (theme: Theme) => theme.palette.grey[400],
          },
        },
      }}
      name={name}
      component={FormikTextField}
      {...commonFieldProps}
      InputProps={{
        ...commonFieldProps.InputProps,
        inputProps: {
          min,
          max,
        },
      }}
      {...otherProps}
    />
  );
};
