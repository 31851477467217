import { PublicVehicle } from '@bisondesk/core-sdk/lib/types/vehicles';
import { LeasingSimulationRequest } from '@bisondesk/core-sdk/lib/types/website';
import { InfoOutlined } from '@mui/icons-material';
import { Box, MenuItem, Typography } from '@mui/material';
import { styled } from '@mui/styles';
import { SxProps } from '@mui/system';
import { Field, Formik } from 'formik';
import { Select } from 'formik-material-ui';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { DEPOSIT_PERCENTAGE, RESIDUAL_PERCENTAGE } from '../../../../constants/leasing';
import { useVehicleLeasingCalculation } from '../../../../data/vehicles';
import { theme } from '../../../../layout/styles';
import { Loading } from '../../../Loading';
import { AutoSaveForm } from './AutoSaveForm';
import { CurrencyField, CurrencyFormikField } from './fields';
import { InfoTooltip } from './InfoTooltip';
import { leasePeriods } from './utils';

const formControlStyles: SxProps = {
  display: 'grid',
  alignItems: 'center',
  gridTemplateColumns: '1fr',
  [theme.breakpoints.up('sm')]: {
    gridGap: '10px',
    gridTemplateColumns: '1fr 1fr',
  },
};

const labelStyles: SxProps = {
  cursor: 'pointer',
};

const InfoIcon = styled(InfoOutlined)({
  color: '#00A333',
  verticalAlign: 'middle',
  fontSize: '1.2rem',
});

type Props = {
  vehicle: PublicVehicle;
};

export const LeaseCalculator = ({ vehicle }: Props) => {
  const availableLeasingTerms = useMemo(
    () => leasePeriods(vehicle.external.history?.advertisingYear ?? 0),
    [vehicle.external.history?.advertisingYear]
  );
  const { t } = useTranslation('leasing-simulator');

  const Translations = useMemo(
    () => ({
      depositExceeded: t('deposit-exceeded'),
      tooltips: {
        purchase: {
          title: t('purchase.title'),
          subtitle: t('purchase.subtitle'),
        },
        deposit: {
          title: t('deposit.title'),
          subtitle: t('deposit.subtitle'),
        },
        term: {
          title: t('term.title'),
          subtitle: t('term.subtitle'),
        },
        residual: {
          title: t('residual.title'),
          subtitle: t('residual.subtitle'),
        },
      },
    }),
    [t]
  );

  const vehicleAskingPrice = Number(vehicle.external.salesConditions.price);

  const initialValues: LeasingSimulationRequest = {
    deposit: vehicleAskingPrice * DEPOSIT_PERCENTAGE,
    durationInMonths: 12,
    vehicleId: vehicle.id,
    residual: vehicleAskingPrice * RESIDUAL_PERCENTAGE,
  };
  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        deposit: Yup.number()
          .required()
          .min(0)
          .max(vehicleAskingPrice, Translations.depositExceeded),

        durationInMonths: Yup.number()
          .typeError('${path} ' + t('common:validations.number.invalid'))
          .required()
          .max(Math.max(...availableLeasingTerms)),
        residual: Yup.number()
          .typeError('${path} ' + t('common:validations.number.invalid'))
          .required()
          .min(0),
      }),
    [availableLeasingTerms, vehicleAskingPrice, Translations, t]
  );

  const { mutateAsync, data, isLoading, isError } = useVehicleLeasingCalculation();

  const onSubmit = (values: LeasingSimulationRequest) => mutateAsync(values);

  return (
    <Box sx={{ maxWidth: '500px', margin: '0 auto' }}>
      <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
        <Box sx={{ display: 'grid', gridAutoFlow: 'row', gridGap: '10px', padding: '2rem 0' }}>
          <Box sx={formControlStyles}>
            <InfoTooltip {...Translations.tooltips.purchase}>
              <Typography sx={labelStyles} variant="body2">
                {t('purchase.title')} <InfoIcon />
              </Typography>
            </InfoTooltip>

            <CurrencyField
              data-testid="purchasePrice"
              name="purchasePrice"
              value={vehicleAskingPrice}
              disabled
            />
          </Box>
          <Box sx={formControlStyles}>
            <InfoTooltip {...Translations.tooltips.deposit}>
              <Typography sx={labelStyles} variant="body2">
                {t('deposit.title')}* <InfoIcon />
              </Typography>
            </InfoTooltip>
            <CurrencyFormikField data-testid="deposit" name="deposit" max={vehicleAskingPrice} />
          </Box>
          <Box sx={formControlStyles}>
            <InfoTooltip {...Translations.tooltips.term}>
              <Typography sx={labelStyles} variant="body2">
                {t('term.title')} <InfoIcon />
              </Typography>
            </InfoTooltip>
            <Field
              name="durationInMonths"
              component={Select}
              data-testid="durationInMonths"
              variant="outlined"
            >
              {availableLeasingTerms.map((term) => (
                <MenuItem value={term} key={term}>
                  {`${term} ${t('glossary:months')}`}
                </MenuItem>
              ))}
            </Field>
          </Box>
          <Box sx={formControlStyles}>
            <InfoTooltip {...Translations.tooltips.residual}>
              <Typography sx={labelStyles} variant="body2">
                {t('residual.title')} <InfoIcon />
              </Typography>
            </InfoTooltip>

            <CurrencyFormikField data-testid="residual" name="residual" min={0} />
          </Box>
          <AutoSaveForm />
        </Box>
      </Formik>
      <Typography
        data-testid="content"
        sx={{ textAlign: 'center', color: theme.palette.success.main }}
        variant="h4"
      >
        {isLoading ? (
          <Loading
            sx={{
              height: '1.2em',
              padding: '0',
              '& .sk-spinner': {
                display: 'flex !important',
              },
              '& .sk-three-bounce > div': {
                height: '0.5em',
                width: '0.5em',
              },
            }}
            data-testid="loading"
          />
        ) : !isError && data != null ? (
          `€${data.monthly} / month`
        ) : (
          '---'
        )}
      </Typography>
    </Box>
  );
};
