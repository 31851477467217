import { Box } from '@mui/material';
import React, { PropsWithChildren } from 'react';
import { MakeSx } from '../types/common';

type Props = PropsWithChildren<{ colors: Colors; style?: React.CSSProperties }>;

type Colors = {
  border: string;
  text: string;
  background: string;
};

export const labelColors = {
  info: {
    border: '#217AB7',
    text: '#1c6697',
    background: '#C4F0FF',
  },
  good: {
    border: '#28a745',
    text: '#207e36',
    background: '#e2f8e7',
  },
  neutral: {
    border: '#919EAB',
    text: '#637381',
    background: '#F4F6F8',
  },
  warning: {
    border: '#E9930C',
    text: '#E9930C',
    background: '#FFF6DB',
  },
  danger: {
    border: '#FF7759',
    text: '#FF7759',
    background: '#FFF7EE',
  },
};

const useSx: MakeSx<{ colors: { border: string; text: string; background: string } }> = (
  props
) => ({
  root: {
    display: 'inline-block',
    border: `1px solid ${props.colors.border}`,
    color: props.colors.text,
    borderRadius: '8px',
    background: props.colors.background,
    padding: '2px 8px',
    textAlign: 'center',
    fontSize: '12px',
    letterSpacing: '0.04em',
  },
});

export const BoolLabel = ({ children, colors, ...others }: Props) => {
  const sxClasses = useSx({ colors });
  return (
    <Box sx={sxClasses.root} {...others}>
      {children}
    </Box>
  );
};
