import Close from '@mui/icons-material/Close';
import {
  Dialog,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
  useMediaQuery,
} from '@mui/material';
import React, { useState } from 'react';
import { theme } from '../layout/styles';
import { SxClasses } from '../types/common';

type ChildrenProps = {
  toggleModal: (overrideState?: boolean) => void;
};
type Props = {
  title?: React.ReactChild;
  activator: React.ReactElement<{ toggleModal: () => void; [key: string]: any }>;
  children?: (args: ChildrenProps) => JSX.Element;
  dialogProps?: Omit<DialogProps, 'open'>;
};

const classes: SxClasses = {
  dialogTitle: {
    display: 'flex',
    alignItems: 'center',
    padding: '1rem',
    justifyContent: 'space-between',
  },
  dialogContent: {
    paddingTop: '1rem !important',
    // paddingBottom: '1rem',
  },
};

export const BaseDialog = ({ activator, children, title, dialogProps }: Props) => {
  const [open, setOpen] = useState(false);
  const toggleModal = (overrideState?: boolean) => {
    setOpen((open) => overrideState ?? !open);
  };

  const fullScreen = useMediaQuery(theme.breakpoints.only('xs'));
  const activatorOnClick = () => {
    activator.props.onClick && activator.props.onClick();
    toggleModal();
  };

  return (
    <>
      {React.cloneElement(activator, { ...activator.props, onClick: activatorOnClick })}
      <Dialog open={open} onClose={() => toggleModal()} fullScreen={fullScreen} {...dialogProps}>
        <DialogTitle sx={classes.dialogTitle}>
          {title}
          <IconButton onClick={() => toggleModal()} color="primary" component="span" size="large">
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={classes.dialogContent}>
          {children && children({ toggleModal })}
        </DialogContent>
      </Dialog>
    </>
  );
};
