import { PublicVehicle } from '@bisondesk/core-sdk/src/types/vehicles';
import React from 'react';
import { Helmet } from 'react-helmet';
import { useMetadata } from '../../context/metadata';
import { useMultilang } from '../MultiLang';
import { getVehicleDescription } from './utils';

type Props = {
  vehicle: PublicVehicle;
  url?: string;
  title?: string;
  description?: string;
  image?: string;
};

export const VehicleSEO = (props: Props) => {
  const { getMultiLangValue } = useMultilang();

  const { siteUrl, storageUrl } = useMetadata();

  const {
    vehicle,
    title = getMultiLangValue(vehicle.external.description?.titles),
    description = `Ref: ${vehicle.external.identification.stockNumber}, ${getVehicleDescription(
      vehicle,
      'es-BO'
    )}`,
    image = `${storageUrl}${vehicle.external.mainPicture.url}?w=1200&h=650&fit=inside`,
    url = `${siteUrl}/vehicles/${vehicle.external.identification.slug}`,
  } = props;

  const tags = [
    // general
    {
      name: 'title',
      content: title,
    },
    {
      name: 'description',
      content: description,
    },
    // facebook / whatsapp / instagram / open graph
    {
      property: 'og:type',
      content: 'website',
    },
    {
      property: 'og:url',
      content: url,
    },
    {
      property: 'og:title',
      content: title,
    },
    {
      property: 'og:description',
      content: description,
    },
    {
      property: 'og:image',
      content: image,
    },
    {
      property: 'og:site_name',
      content: 'HPA Bolivia',
    },
    // twitter
    {
      property: 'twitter:card',
      content: 'summary_large_image',
    },
    {
      property: 'twitter:url',
      content: url,
    },
    {
      property: 'twitter:title',
      content: title,
    },
    {
      property: 'twitter:description',
      content: description,
    },
    {
      property: 'twitter:image',
      content: image,
    },
  ].filter((meta) => !!meta.content);

  return (
    <Helmet title={title}>
      {tags.map((tag) => (
        <meta key={tag.property ?? tag.name} {...tag}></meta>
      ))}
    </Helmet>
  );
};
