import { PublicVehicle } from '@bisondesk/core-sdk/lib/types/vehicles';
import { Box, Paper, styled, useMediaQuery } from '@mui/material';
import { SxProps } from '@mui/system';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { colors, theme } from '../../../layout/styles';
import { DisplayImages } from '../../DisplayImages';
import { LeaseCalculator } from '../details/LeaseCalculator';
import { SocialShare } from '../SocialShare';
import { VehicleSEO } from '../VehicleSEO';
import Contact from './Contact';
import VehicleFullSpecs from './VehicleFullSpecs';
import VehicleSpecsSummary from './VehicleSpecsSummary';
type Props = {
  vehicle: PublicVehicle;
};

const rootStyles: SxProps = {
  display: 'grid',
  gridTemplateColumns: '1fr minmax(350px, 30%)',
  gap: '16px',

  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '1fr',
  },

  '& h2': {
    fontSize: '1.3rem',
  },
};

const imagesStyles: SxProps = {
  '& .image-gallery-content, & .image-gallery-slide-wrapper, & .image-gallery-slide *': {
    minHeight: '200px',
  },
  '& .image-gallery-thumbnail.active, & .image-gallery-thumbnail:hover': {
    borderColor: colors.primary.A500,
  },
  '& .image-gallery-icon:hover': {
    color: colors.primary.A500,
  },

  '@media print': {
    '& .image-gallery-content, & .image-gallery-slide-wrapper, & .image-gallery-slide *': {
      height: '200px',
    },
    '& .image-gallery-icon': {
      display: 'none',
    },
    height: '200px',
    overflow: 'hidden',
  },
};

const StyledPaper = styled(Paper)({
  position: 'relative',
  padding: '1rem',
  marginBottom: '1rem',

  '@media print': {
    boxShadow: 'none',
  },
});

export default ({ vehicle }: Props) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { t } = useTranslation('glossary');

  const LeftColumnMobile = useMemo(
    () => (
      <>
        <StyledPaper>
          <VehicleSpecsSummary vehicle={vehicle} />
        </StyledPaper>
        <StyledPaper sx={{ pb: 0 }}>
          <Contact vehicle={vehicle} />
        </StyledPaper>
        <Box sx={theme.typography.body1}>
          <StyledPaper>
            <VehicleFullSpecs vehicle={vehicle} />
          </StyledPaper>
        </Box>
        {vehicle.external.salesConditions.price && vehicle.external.salesConditions.leasing ? (
          <StyledPaper>
            <h2>{t('lease-calculator')}</h2>
            <LeaseCalculator vehicle={vehicle} />
          </StyledPaper>
        ) : null}
        <SocialShare vehicle={vehicle} />
      </>
    ),
    [vehicle, t]
  );

  const LeftColumnDesktop = useMemo(
    () => (
      <Box sx={theme.typography.body1}>
        <StyledPaper>
          <VehicleFullSpecs vehicle={vehicle} />
        </StyledPaper>
      </Box>
    ),
    [vehicle]
  );

  const RightColumnDesktop = useMemo(
    () => (
      <>
        <StyledPaper>
          <VehicleSpecsSummary vehicle={vehicle} />
        </StyledPaper>
        <StyledPaper sx={{ pb: 0 }}>
          <Contact vehicle={vehicle} />
        </StyledPaper>
        {vehicle.external.salesConditions.price && vehicle.external.salesConditions.leasing ? (
          <StyledPaper>
            <h2>{t('lease-calculator')}</h2>
            <LeaseCalculator vehicle={vehicle} />
          </StyledPaper>
        ) : null}
        <SocialShare vehicle={vehicle} />
      </>
    ),
    [vehicle, t]
  );

  return (
    <Box sx={rootStyles}>
      <div>
        <VehicleSEO vehicle={vehicle} />
        <Box sx={imagesStyles}>
          <StyledPaper sx={{ p: 0 }}>
            <DisplayImages value={vehicle.external.pictures} />
          </StyledPaper>
        </Box>
        {isMobile ? LeftColumnMobile : LeftColumnDesktop}
      </div>
      {!isMobile && <div>{RightColumnDesktop}</div>}
    </Box>
  );
};
