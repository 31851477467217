import { PublicVehicle } from '@bisondesk/core-sdk/lib/types/vehicles';
import { ShareButtons } from '@bisondesk/ttc-ui-library';
import { Box, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useMetadata } from '../../context/metadata';
import { theme } from '../../layout/styles';
import { concatSx } from '../../utils/formatters';
import { useMultilang } from '../MultiLang';
import { getVehicleDescription } from './utils';

type Props = {
  vehicle: PublicVehicle;
  sx?: SxProps;
};

const containerStyles: SxProps = {
  display: 'grid',
  gridAutoRows: '36px',
  gridTemplateColumns: 'repeat(auto-fit, 36px)',
  gap: '1rem',
  paddingTop: '10px',
  [theme.breakpoints.only('xs')]: {
    justifyContent: 'center',
  },
};

export const SocialShare = ({ vehicle, sx }: Props) => {
  const { siteUrl, siteTitle } = useMetadata();
  const { t } = useTranslation('glossary');
  const currentUrl = useMemo(
    () => `${siteUrl}/vehicles/${vehicle.external.identification.slug}`,
    [vehicle, siteUrl]
  );
  const { getMultiLangValue } = useMultilang();

  const vehicleDescription = getVehicleDescription(vehicle, 'es-BO');
  const vehicleTitle = useMemo(
    () => getMultiLangValue(vehicle.external.description.titles),
    [vehicle, getMultiLangValue]
  );

  return (
    <>
      <Typography sx={{ color: theme.palette.grey[700] }}>{t('share-this-vehicle')}</Typography>
      <Box sx={concatSx(containerStyles, sx)}>
        <ShareButtons.Facebook url={currentUrl} />
        <ShareButtons.Email
          url={currentUrl}
          subject={`${siteTitle} :: ${vehicleTitle}`}
          message={`${vehicleTitle}\r\n${vehicleDescription}\r\nRef: ${vehicle.external.identification.stockNumber}\r\n\r\n${currentUrl}`}
        />
        <ShareButtons.WhatsApp url={currentUrl} />
        <ShareButtons.Telegram url={currentUrl} />
        <ShareButtons.LinkedIn url={currentUrl} />
      </Box>
    </>
  );
};
