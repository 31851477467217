import { PublicVehicle } from '@bisondesk/core-sdk/lib/types/vehicles';
import { Dimensions, hasDimensions, VehicleAxleInformation } from '@bisondesk/ttc-ui-library';
import { Box, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { theme } from '../../../layout/styles';
import { MultiLang } from '../../MultiLang';
import { VehicleSpecifications } from './VehicleSpecifications';

type Props = {
  vehicle: PublicVehicle;
};

const VehicleDescription = ({ vehicle }: Props) => {
  const { t } = useTranslation('glossary');
  return (
    <section>
      <h2>{t('description')}</h2>
      <Box
        sx={{
          display: 'grid',
          gap: '5px',
          '& p, pre': {
            ...theme.typography.body1,
            wordBreak: 'break-word',
            whiteSpace: 'normal',
          },
          '& pre': {
            whiteSpace: 'pre-line',
          },
        }}
      >
        <p>
          <MultiLang values={vehicle.external.description?.titles} />
        </p>
        <pre>
          <MultiLang values={vehicle.external.description?.remarks} />
        </pre>
      </Box>
    </section>
  );
};

const SectionTitle = ({ title }: { title: string }) => (
  <Typography variant="h2" sx={{ ...theme.typography.h3, marginBottom: '1rem' }}>
    {title}
  </Typography>
);

const valueFormatters = {
  hp: (value: number | string) => (value ? `${value}hp` : undefined),
  formatDateToYear: (date: string | Date) => new Date(date).getFullYear() || undefined,
};

export default ({ vehicle }: Props) => {
  const { t } = useTranslation('glossary');

  const vehicleSpecifications = [
    {
      title: t('information'),
      items: [
        {
          label: t('vehicle.category'),
          value: vehicle.external.general?.category,
        },
      ],
    },
    {
      title: t('registration'),
      items: [
        {
          label: t('vehicle.year'),
          value: valueFormatters.formatDateToYear(vehicle?.external?.history?.firstRegistration),
        },
        {
          label: t('vehicle.chassis-number'),
          value: vehicle?.external?.identification?.vin,
        },
      ],
    },
    {
      title: t('vehicle.drivetrain'),
      items: [
        {
          label: t('vehicle.euronorm'),
          value: vehicle.external.powertrain?.emissions?.class,
        },
        {
          label: t('vehicle.gearbox'),
          value: vehicle.external.powertrain?.transmission?.type,
        },
        {
          label: t('vehicle.power'),
          value: valueFormatters.hp(vehicle?.external?.powertrain?.engine?.power?.hp),
        },
      ],
    },
  ];

  return (
    <Grid container spacing={3} sx={{ flexGrow: 1 }}>
      <Grid item xs={12}>
        <VehicleDescription vehicle={vehicle} />
      </Grid>
      <Grid item xs={12}>
        <VehicleSpecifications features={vehicleSpecifications} vehicle={vehicle} />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={6}>
        <SectionTitle title={t('vehicle.axles')} />
        <VehicleAxleInformation vehicle={vehicle} />
      </Grid>
      {hasDimensions(vehicle) && (
        <Grid item xs={12} sm={12} md={12} lg={6}>
          <SectionTitle title={t('vehicle.size')} />
          <Dimensions vehicle={vehicle} />
        </Grid>
      )}
    </Grid>
  );
};
