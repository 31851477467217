import { Theme, Tooltip, Typography } from '@mui/material';
import React from 'react';

type InfoTooltipProps = {
  title: React.ReactNode;
  subtitle: React.ReactNode;
  children: React.ReactElement;
};

export const InfoTooltip = ({ title, subtitle, children }: InfoTooltipProps) => {
  return (
    <Tooltip
      title={
        <>
          <Typography
            variant="body2"
            sx={{ fontWeight: (theme: Theme) => theme.typography.fontWeightBold, display: 'block' }}
          >
            {title}
          </Typography>
          <Typography variant="caption">{subtitle}</Typography>
        </>
      }
    >
      {children}
    </Tooltip>
  );
};
