import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

const MessagingWhatsapp = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path
        d="M11.97,23.809c-2.257,0-4.456-0.635-6.368-1.836l-4.195,1.334c-0.05,0.016-0.101,0.023-0.151,0.023
		c-0.134,0-0.264-0.054-0.357-0.15C0.765,23.046,0.72,22.85,0.781,22.67l1.354-3.995C0.736,16.682-0.002,14.343,0,11.902
		C0.033,5.335,5.401-0.007,11.966-0.007h0.065C18.598-0.007,23.967,5.335,24,11.9c-0.02,2.316-0.701,4.56-1.969,6.492
		c-2.226,3.392-5.987,5.417-10.06,5.417H11.97z M5.674,20.926c0.098,0,0.193,0.028,0.274,0.082c1.796,1.179,3.879,1.802,6.024,1.802
		c3.734,0,7.182-1.857,9.223-4.967c1.162-1.771,1.786-3.827,1.805-5.945C22.969,5.887,18.05,0.993,12.033,0.993
		c-0.009,0-0.066,0-0.066,0C5.95,0.993,1.03,5.888,1,11.904c-0.002,2.315,0.723,4.526,2.094,6.393
		c0.096,0.131,0.123,0.302,0.071,0.456l-1.119,3.301l3.476-1.105C5.572,20.934,5.623,20.926,5.674,20.926z"
      />
      <path
        d="M15.701,18.812c-0.464,0-1.237-0.117-3.454-0.983c-2.123-0.83-4.214-2.642-5.886-5.103c0,0-0.049-0.073-0.057-0.083
		c-1.918-2.526-1.977-4.896-0.17-6.857c0.344-0.372,0.906-0.594,1.504-0.594c0.137,0,0.275,0.012,0.408,0.035
		C8.625,5.33,9.065,5.648,9.254,6.1C9.36,6.352,9.524,6.746,9.69,7.148c0.233,0.562,0.54,1.303,0.583,1.39
		c0.178,0.285,0.193,0.685,0.028,1.022c-0.228,0.487-0.548,0.922-0.95,1.288c-0.007,0.008-0.032,0.032-0.053,0.055
		c0.48,0.815,1.076,1.549,1.771,2.185c0.75,0.661,1.608,1.185,2.546,1.555c0.084,0.041,0.131,0.058,0.155,0.065
		c0.005-0.005,0.009-0.011,0.014-0.017c0.203-0.227,0.823-0.952,1.019-1.245c0.21-0.313,0.483-0.472,0.812-0.472
		c0.211,0,0.394,0.066,0.528,0.114c0.33,0.119,1.87,0.872,2.334,1.1l0.175,0.084c0.278,0.133,0.5,0.239,0.648,0.446
		c0.014,0.018,0.028,0.037,0.04,0.057c0.288,0.48,0.237,1.208-0.138,1.945c-0.425,0.836-1.388,1.854-2.705,1.973
		c-0.068,0.006-0.13,0.021-0.208,0.04C16.132,18.77,15.956,18.812,15.701,18.812z M7.644,6.192c-0.314,0-0.625,0.109-0.775,0.271
		c-1.456,1.58-1.378,3.455,0.231,5.574c0.016,0.021,0.045,0.063,0.088,0.126c1.561,2.295,3.487,3.976,5.424,4.733
		c1.589,0.621,2.572,0.91,3.095,0.91c0.15,0,0.245-0.023,0.347-0.048c0.102-0.024,0.214-0.051,0.354-0.064
		c0.911-0.082,1.595-0.821,1.904-1.43c0.224-0.44,0.252-0.794,0.185-0.951c-0.055-0.036-0.197-0.104-0.276-0.142l-0.174-0.084
		c-0.746-0.367-2.013-0.979-2.241-1.061c-0.07-0.025-0.121-0.042-0.154-0.051c-0.005,0.007-0.01,0.015-0.017,0.024
		c-0.245,0.366-0.896,1.121-1.105,1.355c-0.212,0.237-0.46,0.357-0.737,0.357c-0.236,0-0.446-0.09-0.583-0.158
		c-1.013-0.398-1.965-0.98-2.809-1.724c-0.775-0.709-1.435-1.523-1.965-2.422c-0.402-0.667,0.055-1.118,0.227-1.287
		c0.316-0.289,0.562-0.621,0.736-0.994c0.019-0.04,0.018-0.077-0.001-0.109C9.322,8.871,9.109,8.357,8.766,7.53
		C8.601,7.131,8.438,6.739,8.332,6.487C8.277,6.356,8.105,6.253,7.871,6.212C7.798,6.199,7.722,6.192,7.644,6.192z"
      />
    </SvgIcon>
  );
};

export default MessagingWhatsapp;
