import { PublicVehicle } from '@bisondesk/core-sdk/lib/types/vehicles';
import { Box, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { colors } from '../../../layout/styles';
import { formatPrice } from '../../../utils/formatters';
import { BoolLabel, labelColors } from '../../BoolLabel';
import { MultiLang } from '../../MultiLang';
interface Props {
  vehicle: PublicVehicle;
}

export default ({ vehicle }: Props) => {
  const { t } = useTranslation('glossary');
  const values = [
    {
      label: t('vehicle.stock-number'),
      value: vehicle.external.identification?.stockNumber,
    },
    {
      label: t('vehicle.category'),
      value: vehicle.external.general.category,
    },
    {
      label: t('vehicle.bodywork'),
      value: vehicle.external.general.bodystyle,
    },
    {
      label: t('vehicle.year'),
      value: vehicle.external.history?.advertisingYear,
    },
    {
      label: t('vehicle.cabin'),
      value: vehicle.external.body?.cabin?.type,
    },
    {
      label: t('vehicle.transmission'),
      value: vehicle.external.powertrain?.transmission?.type,
    },
    {
      label: t('vehicle.suspension'),
      value: vehicle.external.powertrain?.axles?.suspension,
    },
    {
      label: t('vehicle.euronorm'),
      value: vehicle.external.powertrain?.emissions?.class,
    },
    {
      label: t('vehicle.configuration'),
      value: vehicle.external.powertrain?.axles?.configuration,
    },
    {
      label: t('vehicle.km'),
      value: vehicle.external.condition?.odometer?.km,
    },
    {
      label: t('leasing'),
      value: vehicle.external.salesConditions.leasing ? (
        <BoolLabel colors={labelColors.good}>{'Yes'}</BoolLabel>
      ) : undefined,
    },
    {
      label: t('vehicle.condition'),
      value:
        vehicle.external.condition?.used === false ? (
          <BoolLabel colors={labelColors.good}>{'New'}</BoolLabel>
        ) : undefined,
    },
    {
      label: t('vehicle.damaged'),
      value: vehicle.external.condition?.damaged ? (
        <BoolLabel colors={labelColors.danger}>{'Yes'}</BoolLabel>
      ) : undefined,
    },
    {
      label: t('vehicle.re-intarder'),
      value: vehicle.external.accessories.retarderIntarder ? (
        <BoolLabel colors={labelColors.good}>{'Yes'}</BoolLabel>
      ) : undefined,
    },
    {
      label: t('vehicle.adr'),
      value: vehicle.external.accessories.adr ? (
        <BoolLabel colors={labelColors.good}>{'Yes'}</BoolLabel>
      ) : undefined,
    },
    {
      label: t('vehicle.crane'),
      value: vehicle.external.superstructure?.crane ? (
        <BoolLabel colors={labelColors.good}>{'Yes'}</BoolLabel>
      ) : undefined,
    },
    {
      label: t('vehicle.tail-lift'),
      value: vehicle.external.superstructure?.tailgate ? (
        <BoolLabel colors={labelColors.good}>{'Yes'}</BoolLabel>
      ) : undefined,
    },
  ].filter((e) => !!e.value);

  const formattedPrice = formatPrice(vehicle.external.salesConditions.price, {
    currency: vehicle.external.salesConditions?.currency,
  });

  return (
    <div>
      <Typography
        variant="h3"
        sx={{ fontSize: '24px', fontWeight: 600 }}
        data-testid="truck-make-model"
      >
        <MultiLang values={vehicle.external.description.titles} />
      </Typography>
      <Box
        data-testid="specs-summary-entries"
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fill, minmax(120px, 1fr))',
          gridGap: '8px',
          margin: '16px 0',
        }}
      >
        {values.map((v) => (
          <div data-testid="spec-entry" key={v.label}>
            <Box
              sx={{
                color: colors.greys.A550,
                fontSize: '12px',
                letterSpacing: '0.04em',
                textTransform: 'uppercase',
                whiteSpace: 'nowrap',
              }}
            >
              {v.label}
            </Box>
            <div>{v.value}</div>
          </div>
        ))}
      </Box>
      <Box
        data-testid="vehicle-price"
        sx={{
          fontSize: '24px',
          borderBottom: '2px solid' + colors.primary.A200,
          display: 'inline-block',
        }}
      >
        {formattedPrice ?? 'N/A'}
      </Box>
    </div>
  );
};
