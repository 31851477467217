import { AttachmentValue } from '@bisondesk/core-sdk/lib/types/fields';
import { ImageGallery, ImageGalleryItem } from '@bisondesk/ttc-ui-library';
import { useMediaQuery } from '@mui/material';
import React, { useMemo } from 'react';
import { theme } from '../layout/styles';
import { useMultilang } from './MultiLang';

type Props = {
  value?: AttachmentValue[];
};

const height = 850;
const width = 650;
const fullScreenHeight = 675;
const fullScreenWidth = 1200;

export const DisplayImages = ({ value }: Props) => {
  const { getMultiLangValue } = useMultilang();
  const isSmallScreen = useMediaQuery(theme.breakpoints.only('xs'));

  const images: ImageGalleryItem[] = useMemo(
    () =>
      value == null
        ? []
        : value.map((img) => ({
            description: img.descriptions ? getMultiLangValue(img.descriptions) : undefined,
            fullscreen: `${img.url}?w=${fullScreenWidth}&h=${fullScreenHeight}&fit=inside`,
            original: `${img.url}?w=${width}&h=${height}&fit=inside`,
            height,
            width,
            fullScreenHeight,
            fullScreenWidth,
            thumbnail: `${img.url}?w=75&h=50&fit=contain`,
          })),
    [getMultiLangValue, value]
  );

  return images.length === 0 ? null : (
    <ImageGallery
      items={images}
      showThumbnails={!isSmallScreen}
      thumbnailPosition={isSmallScreen ? undefined : 'left'}
    />
  );
};
