import { useFormikContext } from 'formik';
import { useEffect, useRef } from 'react';
import { useDebouncedCallback } from 'use-debounce';

export const AutoSaveForm = ({ timeout = 250 }: { timeout?: number }): null => {
  const formik = useFormikContext();

  const lastValuesRef = useRef<any>({
    current: null,
  });

  const debounced = useDebouncedCallback(() => {
    formik.handleSubmit();
  }, timeout);

  useEffect(() => {
    if (lastValuesRef.current !== formik.values) {
      debounced();
      lastValuesRef.current = formik.values;
    }
  }, [formik.values, debounced]);

  return null;
};
