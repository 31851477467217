import { PublicVehicle } from '@bisondesk/core-sdk/src/types/vehicles';
import { MailOutlined, PhoneAndroidOutlined } from '@mui/icons-material';
import { Box, Button, styled, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useMetadata } from '../../../context/metadata';
import { BaseDialog } from '../../BaseDialog';
import { CallForm } from '../../forms/CallForm';
import { ContactForm } from '../../forms/ContactForm';
import MessagingWhatsapp from '../../icons/MessagingWhatsapp';

type Props = {
  vehicle: PublicVehicle;
};
const COL_GAP = '1rem';

const RoundButton = styled(Button)({
  borderRadius: '3.125rem',
});

const CallTitle = styled(Typography)({
  display: 'flex',
  alignItems: 'center',
  '& svg': {
    marginRight: '10px',
    verticalAlign: 'middle',
    fontSize: '1em',
    lineHeight: 'inherit',
  },
}) as typeof Typography;

const MessageDialog = ({ vehicle }: Props) => {
  const { t } = useTranslation('glossary');
  const whatsAppText = t('common:contact-default-message', {
    stockNumber: vehicle.external.identification.stockNumber,
  });
  return (
    <BaseDialog
      activator={
        <RoundButton color="primary" variant="contained" fullWidth>
          {t('message')}
        </RoundButton>
      }
      title={
        <CallTitle variant="h4" component="div">
          <MailOutlined /> {t('contact-us')}
        </CallTitle>
      }
    >
      {({ toggleModal }) => (
        <ContactForm defaultMessage={whatsAppText} onSuccess={() => toggleModal()} />
      )}
    </BaseDialog>
  );
};

const CallDialog = () => {
  const { t } = useTranslation('glossary');
  return (
    <BaseDialog
      activator={
        <RoundButton color="primary" variant="outlined" fullWidth>
          {t('call')}
        </RoundButton>
      }
      title={
        <CallTitle variant="h4">
          <PhoneAndroidOutlined />
          {t('call')}
        </CallTitle>
      }
      dialogProps={{
        fullScreen: false,
        maxWidth: 'sm',
        fullWidth: true,
      }}
    >
      {CallForm}
    </BaseDialog>
  );
};

export default ({ vehicle }: Props) => {
  const { phone } = useMetadata();
  const { t } = useTranslation('glossary');

  const whatsAppText = t('common:contact-default-message', {
    stockNumber: vehicle.external.identification.stockNumber,
  });
  const whatsAppUrl = useMemo(
    () => `https://api.whatsapp.com/send?phone=${phone}&text=${whatsAppText}`,
    [phone, whatsAppText]
  );

  return (
    <div>
      <h2>{t('lets-talk')}</h2>
      <Box
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexWrap: 'wrap' }}
      >
        <Box
          sx={{
            flex: 'none',
            position: 'relative',
            paddingBottom: 0,
            padding: COL_GAP,
            '& img': {
              display: 'block',
            },
          }}
        >
          <img width="203" height="205" src={'/images/edgar.png'} alt="Edgar" />
          <Box sx={{ position: 'absolute', left: 0, bottom: '10px' }}>
            <strong>Edgar</strong>
            <p>Product specialist</p>
          </Box>
        </Box>
        <Box
          sx={{
            flex: '1 1 auto',
            maxWidth: '350px',
            minWidth: '150px',
            display: 'grid',
            gap: '8px',
            padding: COL_GAP,
          }}
        >
          <MessageDialog vehicle={vehicle} />
          <CallDialog />
          <a target="_blank" rel="noopener noreferrer" href={whatsAppUrl}>
            <RoundButton fullWidth variant="outlined" color="primary">
              <MessagingWhatsapp fontSize="small" style={{ marginRight: '0.5rem' }} />
              WhatsApp
            </RoundButton>
          </a>
        </Box>
      </Box>
    </div>
  );
};
