import { LEASING_TERMS, MAX_LEASING_AGE_YEARS } from '../../../../constants/leasing';

export function leasePeriods(vehicleYear: number): number[] {
  const currentYear = new Date().getFullYear();
  const vehicleAge = currentYear - vehicleYear;
  const maxPeriod = Math.max(0, (MAX_LEASING_AGE_YEARS - vehicleAge) * 12);

  const maxLease = Math.max(...LEASING_TERMS);
  const minLease = Math.min(...LEASING_TERMS);

  const maxTerm = Math.min(Math.max(maxPeriod, minLease), maxLease);

  const terms = LEASING_TERMS.filter((term) => term <= maxTerm);

  return terms;
}
